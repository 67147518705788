// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import {
  AptosConnectNamespace,
  AptosDisconnectNamespace,
  AptosFeatures,
  AptosGetAccountNamespace,
  AptosGetNetworkNamespace,
  AptosOnAccountChangeNamespace,
  AptosOnNetworkChangeNamespace,
  AptosSignAndSubmitTransactionFeature,
  AptosSignAndSubmitTransactionNamespace,
  AptosSignMessageNamespace,
  AptosSignTransactionNamespace,
  AptosWallet,
} from '@aptos-labs/wallet-standard';
import { AptosConnectWallet, AptosConnectWalletConfig } from './AptosConnectWallet';
import { walletMetadata } from './config';

export class AptosConnectGoogleWallet extends AptosConnectWallet implements AptosWallet {
  readonly name = walletMetadata.google.name;

  readonly icon = walletMetadata.google.icon;

  readonly url = walletMetadata.google.url;

  constructor(config: AptosConnectWalletConfig) {
    super({ ...config, provider: 'google' });
  }

  // region AptosWallet

  get features(): AptosFeatures & AptosSignAndSubmitTransactionFeature {
    return {
      [AptosConnectNamespace]: {
        connect: this.connect.bind(this),
        version: '1.0.0',
      },
      [AptosDisconnectNamespace]: {
        disconnect: this.disconnect.bind(this),
        version: '1.0.0',
      },
      [AptosGetAccountNamespace]: {
        account: this.getAccount.bind(this),
        version: '1.0.0',
      },
      [AptosGetNetworkNamespace]: {
        network: this.getNetwork.bind(this),
        version: '1.0.0',
      },
      [AptosOnAccountChangeNamespace]: {
        onAccountChange: this.onAccountChange.bind(this),
        version: '1.0.0',
      },
      [AptosOnNetworkChangeNamespace]: {
        onNetworkChange: this.onNetworkChange.bind(this),
        version: '1.0.0',
      },
      [AptosSignAndSubmitTransactionNamespace]: {
        signAndSubmitTransaction: this.signAndSubmitTransaction.bind(this),
        version: '1.1.0',
      },
      [AptosSignMessageNamespace]: {
        signMessage: this.signMessage.bind(this),
        version: '1.0.0',
      },
      [AptosSignTransactionNamespace]: {
        signTransaction: this.signTransaction.bind(this),
        version: '1.1',
      },
    };
  }

  // endregion
}
