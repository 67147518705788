// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { AccountInfo as ACAccountInfo, UserResponse as ACUserResponse } from '@aptos-connect/wallet-api';
import { Network, NetworkToChainId } from '@aptos-labs/ts-sdk';
import { AccountInfo, UserResponse, UserResponseStatus } from '@aptos-labs/wallet-standard';

export function customAccountToStandardAccount({ address, name, publicKey }: ACAccountInfo) {
  return new AccountInfo({
    address,
    ansName: name,
    publicKey,
  });
}

export function unwrapUserResponse<T, U>(response: ACUserResponse<T>, callback: (args: T) => U): UserResponse<U> {
  if (response.status === 'dismissed') {
    return { status: UserResponseStatus.REJECTED };
  }
  return { args: callback(response.args), status: UserResponseStatus.APPROVED };
}

export function networkToChainId(network: Network): number {
  switch (network) {
    case Network.MAINNET:
      return NetworkToChainId.mainnet;
    case Network.TESTNET:
      return NetworkToChainId.testnet;
    default:
      return NetworkToChainId.devnet;
  }
}
