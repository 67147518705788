// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

export const walletMetadata = {
  apple: {
    icon:
      // eslint-disable-next-line max-len
      'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxzdHlsZT4KICAgICAgICBwYXRoIHsKICAgICAgICAgICAgZmlsbDogYmxhY2s7CiAgICAgICAgfQoKICAgICAgICBAbWVkaWEgKHByZWZlcnMtY29sb3Itc2NoZW1lOiBkYXJrKSB7CiAgICAgICAgICAgIHBhdGggewogICAgICAgICAgICAgICAgZmlsbDogd2hpdGU7CiAgICAgICAgICAgIH0KICAgICAgICB9CiAgICA8L3N0eWxlPgogICAgPHBhdGgKICAgICAgICBkPSJNMjAuMzkzMiA4LjE4MjQyQzIwLjI1NzggOC4yOTA0MyAxNy44NjggOS42NzUzNyAxNy44NjggMTIuNzU0OUMxNy44NjggMTYuMzE2OCAyMC45MDkgMTcuNTc3IDIxIDE3LjYwODJDMjAuOTg2IDE3LjY4NSAyMC41MTY5IDE5LjMzMzkgMTkuMzk2NiAyMS4wMTQxQzE4LjM5NzcgMjIuNDkyNiAxNy4zNTQ1IDIzLjk2ODggMTUuNzY3NSAyMy45Njg4QzE0LjE4MDQgMjMuOTY4OCAxMy43NzIgMjMuMDIwNyAxMS45Mzk5IDIzLjAyMDdDMTAuMTU0NSAyMy4wMjA3IDkuNTE5NzIgMjQgOC4wNjgwNSAyNEM2LjYxNjM5IDI0IDUuNjAzNDkgMjIuNjMxOSA0LjQzODg5IDIwLjk1MTdDMy4wODk5MiAxOC45Nzg3IDIgMTUuOTEzNiAyIDEzLjAwNDVDMiA4LjMzODQzIDQuOTUwMDEgNS44NjM3OSA3Ljg1MzM0IDUuODYzNzlDOS4zOTYwMiA1Ljg2Mzc5IDEwLjY4MiA2LjkwNTQ5IDExLjY1MDUgNi45MDU0OUMxMi41NzI0IDYuOTA1NDkgMTQuMDEwMSA1LjgwMTM4IDE1Ljc2NTEgNS44MDEzOEMxNi40MzAzIDUuODAxMzggMTguODIwMiA1Ljg2Mzc5IDIwLjM5MzIgOC4xODI0MlpNMTQuOTMxOSAzLjgyNTk4QzE1LjY1NzggMi45NDAyOSAxNi4xNzEyIDEuNzExMzcgMTYuMTcxMiAwLjQ4MjQ0OEMxNi4xNzEyIDAuMzEyMDMxIDE2LjE1NzIgMC4xMzkyMTQgMTYuMTI2OSAwQzE0Ljk0NiAwLjA0NTYwNDYgMTMuNTQxIDAuODA4ODgxIDEyLjY5MzggMS44MTkzOEMxMi4wMjg2IDIuNTk3MDYgMTEuNDA3OCAzLjgyNTk4IDExLjQwNzggNS4wNzE3MUMxMS40MDc4IDUuMjU4OTMgMTEuNDM4MiA1LjQ0NjE0IDExLjQ1MjIgNS41MDYxNUMxMS41MjY4IDUuNTIwNTUgMTEuNjQ4MiA1LjUzNzM1IDExLjc2OTYgNS41MzczNUMxMi44MjkxIDUuNTM3MzUgMTQuMTYxOCA0LjgwNzY4IDE0LjkzMTkgMy44MjU5OFoiIC8+Cjwvc3ZnPg==',
    name: 'Continue with Apple',
    url: 'https://aptosconnect.app',
  },
  google: {
    icon:
      // eslint-disable-next-line max-len
      'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0iaF8yMCB3XzIwIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIzLjU0IDEyLjc2MTNDMjMuNTQgMTEuOTQ1OSAyMy40NjY4IDExLjE2MTggMjMuMzMwOSAxMC40MDkxSDEyLjVWMTQuODU3NUgxOC42ODkxQzE4LjQyMjUgMTYuMjk1IDE3LjYxMjMgMTcuNTEyOSAxNi4zOTQzIDE4LjMyODRWMjEuMjEzOEgyMC4xMTA5QzIyLjI4NTUgMTkuMjExOCAyMy41NCAxNi4yNjM2IDIzLjU0IDEyLjc2MTNaIiBmaWxsPSIjNDI4NUY0Ij48L3BhdGg+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi40OTk1IDIzLjk5OThDMTUuNjA0NSAyMy45OTk4IDE4LjIwNzcgMjIuOTcgMjAuMTEwNCAyMS4yMTM3TDE2LjM5MzggMTguMzI4MkMxNS4zNjQgMTkuMDE4MiAxNC4wNDY3IDE5LjQyNTkgMTIuNDk5NSAxOS40MjU5QzkuNTA0MjUgMTkuNDI1OSA2Ljk2OTAyIDE3LjQwMyA2LjA2NDcgMTQuNjg0OEgyLjIyMjY2VjE3LjY2NDRDNC4xMTQ5MyAyMS40MjI4IDguMDA0MDIgMjMuOTk5OCAxMi40OTk1IDIzLjk5OThaIiBmaWxsPSIjMzRBODUzIj48L3BhdGg+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjA2NTIzIDE0LjY4NTFDNS44MzUyMyAxMy45OTUxIDUuNzA0NTUgMTMuMjU4MSA1LjcwNDU1IDEyLjUwMDFDNS43MDQ1NSAxMS43NDIyIDUuODM1MjMgMTEuMDA1MSA2LjA2NTIzIDEwLjMxNTFWNy4zMzU1N0gyLjIyMzE4QzEuNDQ0MzIgOC44ODgwNyAxIDEwLjY0NDQgMSAxMi41MDAxQzEgMTQuMzU1OCAxLjQ0NDMyIDE2LjExMjIgMi4yMjMxOCAxNy42NjQ3TDYuMDY1MjMgMTQuNjg1MVoiIGZpbGw9IiNGQkJDMDUiPjwvcGF0aD48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLjQ5OTUgNS41NzM4NkMxNC4xODc5IDUuNTczODYgMTUuNzAzOCA2LjE1NDA5IDE2Ljg5NTYgNy4yOTM2NEwyMC4xOTQgMy45OTUyM0MxOC4yMDI0IDIuMTM5NTUgMTUuNTk5MiAxIDEyLjQ5OTUgMUM4LjAwNDAyIDEgNC4xMTQ5MyAzLjU3NzA1IDIuMjIyNjYgNy4zMzU0NUw2LjA2NDcgMTAuMzE1QzYuOTY5MDIgNy41OTY4MiA5LjUwNDI1IDUuNTczODYgMTIuNDk5NSA1LjU3Mzg2WiIgZmlsbD0iI0VBNDMzNSI+PC9wYXRoPjwvc3ZnPg==',
    name: 'Continue with Google',
    url: 'https://aptosconnect.app',
  },
} as const;
